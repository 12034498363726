<script setup>
import { useViewBuildings, useGenerateHouseBill } from "./buildings_business_logic";
import ViewBuildingsTable from "@/components/buildings/tables/ViewBuildingsTable.vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import { computed, ref, onMounted } from "vue";
import { resetSnackbarOnMount, snackbarData } from "@/components/utils/snackbar/logic";
import { useRouter, useRouterFromInstance } from "@/utils/vue_helpers";
import Pagination from "../utils/Pagination.vue";
import LoadingArea from "../utils/LoadingArea.vue";
import { routes } from "@/router/routes";
import Index from "./Index.vue";
import Instructions from "./Instructions.vue";
import Table from "@/components/utils/Table.vue";

resetSnackbarOnMount();

const { router } = useRouterFromInstance();

const { buildingsData, searchModel } = useViewBuildings();

const generateHouseBill = useGenerateHouseBill();

const pages = {
  VIEW_BUILDINGS_DETAILS: 0,
  VIEW_HOUSE: 1,
  VIEW_MAKE_BUILDING_APPLICATION: 2,
  VIEW_VEHICLE_DETAILS: 3,
  VIEW_BUILDINGS_INSTRUCTION_DETAILS: 4,
};

const currentPage = ref(pages.VIEW_BUILDINGS_INSTRUCTION_DETAILS); //ref(pages.VIEW_BUILDINGS_DETAILS)

const buildingUnderView = ref(null);



function moveToViewMakeBuildingApplication(){
  currentPage.value = pages.VIEW_MAKE_BUILDING_APPLICATION;

}


function moveToViewHouseTable() {
  currentPage.value = pages.VIEW_BUILDINGS_INSTRUCTION_DETAILS;
}

function moveToViewBuildings(){
  currentPage.value = pages.VIEW_BUILDINGS_DETAILS

}

async function onPaginationClicked(newPage) {
  buildingsData.paginationData.currentPage = newPage;
}

onMounted(() => {
  console.log('Inside OnMounted')
  console.log(buildingsData)

  console.log('CurrentPage Value')
  console.log(currentPage.value)
 

})

</script>

<template>
  <v-container fluid class="pa-0">
    <v-tabs-items v-model="currentPage" touchless continuous>
      <v-tab-item :value="pages.VIEW_BUILDINGS_INSTRUCTION_DETAILS">
        <v-row>
          <v-col cols="12">
            <Instructions @goBackClicked="moveToViewBuildings" @goNextSection="moveToViewMakeBuildingApplication">
              
            </Instructions>

          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item :value="pages.VIEW_MAKE_BUILDING_APPLICATION">
        <v-row>
          <v-col cols="12">
            <Index :house="buildingUnderView" :loading="generateHouseBill.isLoading.value"
              @goBackClicked="moveToViewHouseTable"></Index>
          </v-col>
        </v-row>
      </v-tab-item>

    </v-tabs-items>
  </v-container>
</template>
