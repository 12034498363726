import { render, staticRenderFns } from "./ViewBuildingsCreateApp.vue?vue&type=template&id=203e12b6&"
import script from "./ViewBuildingsCreateApp.vue?vue&type=script&setup=true&lang=js&"
export * from "./ViewBuildingsCreateApp.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports